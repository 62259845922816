<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M18.643 20.5H2.357A1.846 1.846 0 0 1 .5 18.665V6.653c0-1.016.833-1.835 1.857-1.835h16.286c1.024 0 1.857.82 1.857 1.835v12.012a1.846 1.846 0 0 1-1.857 1.835zm0-1a.846.846 0 0 0 .857-.835V6.653a.846.846 0 0 0-.857-.835H2.357a.846.846 0 0 0-.857.835v12.012c0 .46.382.835.857.835h16.286zM8.41 3.59a2.092 2.092 0 0 1 4.18 0 .5.5 0 1 0 1 0 3.092 3.092 0 0 0-6.18 0 .5.5 0 1 0 1 0z"
    />
    <path fill-rule="nonzero" d="M1 16.182h19a.5.5 0 1 0 0-1H1a.5.5 0 1 0 0 1z" />
    <path d="M11.364 11.795a1.295 1.295 0 1 1-2.59.001 1.295 1.295 0 0 1 2.59 0" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
